body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.numberingraph{
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
}

.notosansjpblack {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 950;
  letter-spacing: -0.5px;
}

.notosansjplight{
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 200;
}

.notosansjplightforprivacy{
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 200;
}

.notosansjpnormal{
  font-family: 'Noto Sans Jp',sans-serif;
  font-weight: 400;
}

.ui.checkbox label{
  color: #1C3255 !important;
  font-size: 9px !important;
}

.ui.dropdown:not(.button)>.default.text{
  color: rgba(28, 50, 85,0.3) !important;
  font-family: 'Noto Sans Jp',sans-serif;
}
.ui.default.dropdown:not(.button)>.text
 {
  color: #1C3255 !important;
  font-family: 'Noto Sans Jp',sans-serif;
}

.ui.selection.dropdown{
  color:#1C3255 !important;
}

.ui.form input{
  color: #1C3255 !important;
  font-family: 'Noto Sans Jp',sans-serif !important;
  font-weight: 400;
  font-size: 14px !important;
  height: 40px;
  line-height: 40px;
  padding-left: 16px !important;
  border-radius: 0 !important;
}

.ui.dropdown>.text{
  font-size: 14px;
  font-family: 'Noto Sans Jp',sans-serif;
  color: #1C3255 !important;
  font-weight: 400;
}

.ui.dropdown .menu>.item{
  background-color:rgb(230, 230, 230) !important;
  font-family: 'Noto Sans Jp',sans-serif;

}

.ui.dropdown .menu>.item:hover{
  background-color: rgb(250,250,250) !important; 
  font-family: 'Noto Sans Jp',sans-serif;

}

#large-picker .dropdown::before,
.large-picker .dropdown::before {
    font-size: 20px;
}

.ui.dimmer{
  background-color: rgba(0,0,0,.7) !important
}

.ui.selection.dropdown{
  border-radius: 0 !important;
}