spaninvisibleintegertext{
    opacity: 0;
    background-color: #ffffff;
    color: black;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
}

bluebackgroundoptiverlogo{
    align-content: right;
    background-color: #ffffff;
    color: #000;
    flex-direction: column;
    justify-content: right;
  }
 
thp {
    align-content: center;
    background-color: #ffffff;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 45em;
}
thp > span {
    text-align: center;
}

thp1 {
    align-content: center;
    background-color: #ffffff;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 3em;
}

thp1 > span1 {
    text-align: left;
    padding-left: 30px;
    font-size: 17px;
}
thp1 > span2 {
    text-align: right;
    padding-right: 30px;
    font-size: 20px;
}

thp2 {
    align-content: center;
    background-color: #ffffff;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 10em;
}
thp2 > span {
    text-align: center;
    font-size: 40px;
    font-weight: bold;
}
thp3 {
    align-content: center;
    background-color: #ffffff;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 10em;
}
thp3 > span {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
}
thp4 {
    align-content: center;
    background-color: #ffffff;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 10em;
}
thp4 > span {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
}

thbuttonSpan{
    font-size:25px;
}
optiverlogo{
    align-content: right;
    background-color: #ffffff;
    color: #000;
    flex-direction: column;
    justify-content: right;
    
}
decoration12{
    align-content: right;
    background-color: #ffffff;
    color: #000;
    flex-direction: column;
    justify-content: right;
}