
ptra {
    align-content: center;
    background-color: #ffffff;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 50em;
}

ptra>span {
    text-align: center;
}

ptra1 {
    align-content: center;
    background-color: #ffffff;
    color: #1C3255;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 3em;
}

ptra1>span1 {
    text-align: left;
    padding-left: 30px;
    font-size: 17px;
}

ptra1>span2 {
    text-align: right;
    padding-right: 30px;
    font-size: 20px;
}

ptra2 {
    align-content: center;
    background-color: #ffffff;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 20em;
}

ptra2>span {
    text-align: center;
}

ptra3 {
    align-content: center;
    background-color: #ffffff;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

ptra3>span {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
}

ptra4 {
    align-content: center;
    background-color: #fff;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 5em;
}

ptra4>span {
    text-align: center;
    font-size: 20px;
    font-weight: bold;

}

ptra5 {
    align-content: center;
    background-color: #fff;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: x-large;
}

ptra5>span {
    text-align: center;
    font-weight: 900;
}

ptra5>span1 {
    text-align: center;
    font-size: -webkit-xxx-large;
    font-weight: bold;
}

buttonSpan {
    font-size: 25px;
}

recordpartbuy {
    align-content: center;
    background-color: #ffffff;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 3em;
    border: 1px solid grey;
    border-left-width: 0px;
    border-right-width: 0px;
    border-top-width: 1px;
    border-bottom-width: 0px;
}

recordpartsell {
    align-content: center;
    background-color: #ffffff;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 3em;
    border: 1px solid grey;
    border-left-width: 0px;
    border-right-width: 0px;
    border-top-width: 1px;
    border-bottom-width: 0px;
}

ptrae {
    align-content: center;
    background-color: #ffffff;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 25em;
}

ptrae>span {
    text-align: left;
    font-size: 22px;
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 600;
    letter-spacing: -0.5px;
}

ptrae1 {
    align-content: center;
    background-color: #ffffff;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 25em;
}

ptrae1>span {
    text-align: center;
    font-size: 22px;
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 950;
    letter-spacing: -0.5px;
}

ptraspaneventtitle {
    background-color: #ffffff;
    color: #ff3300;
    text-align: center;
    font-size: 32px;
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 950;
    letter-spacing: -0.5px;
}

ptraspanask {
    background-color: #ffffff;
    color: black;
    text-align: left;
    font-size: 22px;
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 950;
    letter-spacing: -0.5px;}

ptraspanintegertext {
    background-color: #ffffff;
    color: black;
    text-align: center;
    font-size: 18px;
    font-weight: 900;
}

ptrae>spaninvisibleintegertext{
    opacity: 0;
    background-color: #ffffff;
    color: black;
    text-align: center;
    font-size: 20px;
    font-weight: 900;
}

ptraspaninvisibleintegertext {
    opacity: 0;
    background-color: #ffffff;
    color: black;
    text-align: center;
    font-size: 20px;
    font-weight: 900;
}

ptraptimer {
    width: auto;
    align-content: right;
    background-color: #EDC3BE;
    color: #ff3300;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: x-large;
    min-height: 2em;
}

ptraoptiverlogo {
    align-content: right;
    background-color: #ffffff;
    color: #000;
    flex-direction: column;
    justify-content: right;
}

.unselectable {
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    /*
      Introduced in IE 10.
      See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
    */
    -ms-user-select: none;
    user-select: none;
 }

numberinbox{
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 900;
    color:#1C3255;
}

numberinpositionbox{
    font-family:'Noto Sans JP', sans-serif ;
    font-weight: 900;
    color:#1C3255;
}

eventbuttontext{
    font-family:'Noto Sans JP', sans-serif;
    font-weight: 300;
    font-size: 18px;
}

spp {
    align-content: center;
    background-color: #ffffff;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 50em;
}
spp > span {
    opacity: 0.4;
    text-align: center;
}

spp1 {
    align-content: center;
    background-color: #ffffff;
    color: #1C3255;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 3em;
}

spp1 > span1 {
    text-align: left;
    padding-left: 30px;
    font-size: 17px;
  }
  spp1 > span2 {
    opacity: 0.8;
    text-align: right;
    padding-right: 30px;
    font-size: 20px;
}

lasttutorial {
    align-content: center;
    background-color: #ffffff;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 30em;
    flex-wrap:wrap;
}
lasttutorial > span {
    text-align: center;
    font-family: 'Noto Sans JP Black';
}
